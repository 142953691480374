<template>
  <v-container fluid>
    <router-view :inspections="inspections" :current-inspection="currentInspection" @update="fetchInspections"
      @newInspection="addInspection">
    </router-view>
  </v-container>
</template>

<script>
import BridgeService from "@/services/BridgeService";

export default {
  name: "bridge-inspection",
  props: ["bridge"],
  data() {
    return {
      inspections: [],
    };
  },
  computed: {
    currentInspection() {
      if (this.inspectionId === undefined || this.inspections.length === 0) {
        return {
          InspectionSpans: [],
        };
      }
      const currentInspection = this.inspections.find(
        (i) => i.id === this.inspectionId
      );
      return currentInspection;
    },
    inspectionId() {
      if (this.$route.params.inspectionId !== undefined) {
        return this.$route.params.inspectionId;
      }
      if (this.inspections.length === 0) {
        return undefined;
      }
      const indexOfMaxValue = this.inspections.reduce(
        (iMax, x, i, arr) =>
          new Date(x.inspection_date) > new Date(arr[iMax].inspection_date)
            ? i
            : iMax,
        0
      );

      return this.inspections[indexOfMaxValue].id;
    },
  },
  watch: {
    bridge: {
      handler: function () {
        this.fetchInspections();
      },
      immediate: true,
    },
  },
  methods: {
    async fetchInspections() {
      if (this.bridge.id) {
        this.inspections = [];
        this.inspections = (
          await BridgeService.getInspections(this.bridge.id)
        ).data.map(i => ({
          ...i,
          "InspectionDataStatus.code": i.InspectionDataStatus.code,
          "InspectionDataStatus.description_eng": i.InspectionDataStatus.description_eng,
          "InspectionDataStatus.description_lao": i.InspectionDataStatus.description_lao,

        }));
      }
    },
    addInspection(inspection) {
      this.inspections.push(inspection);
    },
  },
};
</script>
