import Api from "@/services/Api";
import { ElementDamage, ElementDamageParams } from "../types";

export default {
  getElementDamages(params: ElementDamageParams) {
    return Api().get(`sys-inspection-damages/${params.inspection_type}/${params.component}/${params.element}/${params.element_material}`);
  },
  getComponents() {
    return Api().get(`components/`);
  },
  getElements() {
    return Api().get(`elements/`);
  },
  saveElementDamages(body: ElementDamage[]) {
    return Api().put(`element-damages/`, body);
  },
  addInspectionSpan(body: ElementDamage[]) {
    return Api().put(`inspection-spans/`, body);
  },
  deleteInspectionSpan(id: number) {
    return Api().delete(`inspection-spans/${id}`);
  },
  getInspectionDamages(id: string) {
    return Api().get(`inspection-damages/${id}`);
  },
  upsertInspection(inspection: any) {
    return Api().put(`inspections/`, inspection);
  },
  getSysInspectionDamage(inspection_type: string) {
    if (inspection_type === 'B') {
      return Api().get(`sys-inspection-damages/`);
    }
    return Api().get(`sys-inspection-damages/${inspection_type}`);
  },
  deleteInspection(id) {
    return Api().delete(`inspections/${id}`);
  },
  deleteElementDamages(body: Int16Array[]) {
    return Api().delete(`element-damages-list/`, { data: { "list": body } });
  },
  index() {
    return Api().get(`inspections/`);
  },
  getInspectionWithHI(bridge_id) {
    return Api().get(`/inspections/${bridge_id}`);
  },
  getAllDamages() {
    return Api().get(`/export/damages`);
  },
};
