<template>
  <v-container fluid>
    <div class="text-center">
      <v-dialog v-model="imageDialog" width="800">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> </v-card-title>

          <ImagesGallery v-if="images.length" :images="images" :parent-id="damageTypeId" file-type="element_damage_photo"
            @update="$emit('update')" :edit="true" />
          <div v-else>No images for this damage.</div>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="imageDialog = false">
              {{ $t('Close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="d-flex flex-column">
      <v-card class="pa-2 d-flex justify-space-between full-width">
        <div class="d-flex">
          <h3 class="mr-4">
            <u>{{ $t("inspection") }}</u>
            <DataExport :data="damages" :headers="exportHeaders" file-name="Inspection" />
          </h3>
          <div v-if="!editInfo">
            <div class="ma-2">{{ $t("inspection-name") }}:
              {{ currentInspection.inspector_name + " | " }}
              {{ $t("inspection-date") }}:
              {{ currentInspection.inspection_date + " | " }}
              {{ $t("inspection_data_status") }}:
              {{ currentInspection.InspectionDataStatus ? currentInspection.InspectionDataStatus[description] : '' }}
            </div>
            <div class="ma-2">HI: {{ inspectionWithHi.hi }}</div>
            <div class="ma-2">{{ currentInspection.note }}</div>
          </div>
          <div class="d-flex flex-column" v-else>
            <div class="ma-2 d-flex">
              <v-text-field v-model="editedInspection.inspector_name" :label="$t('inspection-name')" class="mr-2" />

              <v-menu v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editedInspection.inspection_date" :label="$t('inspection-date')"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="editedInspection.inspection_date" @input="datePickerMenu = false"></v-date-picker>
              </v-menu>

              <v-select v-model="editedInspection.inspection_data_status" :label="$t('inspection_data_status')"
                :items="dataStatusList" :item-text="description" item-value="code" class="mr-2" />
            </div>
            <div class="ma-2">
              <v-textarea v-model="editedInspection.note" :label="$t('notes')" rows="4" />
            </div>
          </div>
        </div>
        <div>
          <v-btn class="ma-2 blue--text" small outlined color="blue" :to="{
            name: 'bridge-inspection-history',
          }">{{ $t("inspection-history") }}</v-btn>

          <v-btn v-if="$store.getters.get_access('module_bridge_management', 2) && !editInfo" class="ma-2 blue--text"
            small outlined color="blue" @click="startEditInspection">{{ $t("edit") }}</v-btn>
          <v-btn v-if="editInfo" class="ma-2 blue--text" small outlined color="blue" @click="cancelEditInspection">{{
            $t("cancel") }}</v-btn>
          <v-btn v-if="editInfo" class="ma-2 blue--text" small outlined color="blue" @click="saveEditInspection">{{
            $t("save") }}</v-btn>
        </div>
      </v-card>
      <!-- span -->
      <v-card class="pa-2">
        <v-row dense class="d-flex align-center">
          <v-col class="text-left">
            <h3>
              <u>{{ $t("spans") }}:</u>
            </h3>
            <div class="d-flex align-center flex-wrap">
              <v-btn v-for="span in currentInspection.InspectionSpans" :key="span.id" class="pa-2 ml-4 my-1" :color="selectedSpanNumber === span.span_number ? 'green' : 'grey'
                " small rounded @click="selectedSpanNumber = span.span_number">{{ span.span_number }}</v-btn>
            </div>
          </v-col>
          <v-col class="text-right">
            <div>
              <v-btn v-if="$store.getters.get_access('module_bridge_management', 2)" class="ma-2 blue--text" small
                outlined color="blue" :to="{
                  path: editPath,
                }">{{ $t("edit") }}</v-btn>

            </div>
          </v-col>
        </v-row>
        <div>{{ $t('Inspectable') }}: {{ currentInspection.InspectionSpans[selectedSpanIndex] ?
          $t(currentInspection.InspectionSpans[selectedSpanIndex].inspectable) : '' }}
        </div>
      </v-card>
    </div>
    <v-card>
      <v-row dense>
        <v-toolbar color="green" dark flat>
          <v-tabs v-model="componentTab">
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab v-for="component in sysComponents" :key="component.code">
              {{ component[description] }}
            </v-tab>
          </v-tabs>
        </v-toolbar>
      </v-row>
      <v-tabs-items v-model="componentTab" class="mt-4">
        <v-tab-item v-for="component in formatedInspectionSpans[selectedSpanIndex]" :key="component.code">
          <div class="d-flex align-center flex-column">
            <v-card class="ma-2 pa-2">
              <table v-for="element in component.elements.filter(element => element.damages.length > 0)"
                :key="element.id">
                <tr>
                  <th style="width: 800px">
                    {{ element.element[description] }}
                  </th>
                  <th style="text-align: center">A</th>
                  <th style="text-align: center">B</th>
                  <th style="text-align: center">C</th>
                  <th style="text-align: center">D</th>
                  <th style="text-align: center">E</th>
                </tr>
                <tr>
                  {{ $t('Material type') }}:
                  <span> {{
                    element.damages[0][`InspectionSpans.ElementDamages.element_material_syscode.${description}`]
                  }} </span>
                </tr>
                <tr v-for="(damage, idx) in element.damages" :key="idx">
                  <td style="text-align: left">
                    <div class="mx-2">
                      {{
                        damage[`InspectionSpans.ElementDamages.damage_type_syscode.${description}`]
                      }}
                      <v-icon color="blue" medium class="mr-2" v-if="damage.images.length" @click="
                        showImages(
                          damage
                        )
                        ">
                        mdi-note-text-outline
                      </v-icon>
                    </div>
                  </td>
                  <td v-for="x in ['a', 'b', 'c', 'd', 'e']" :key="x">
                    <label>{{
                      damage[`InspectionSpans.ElementDamages.damage_grade_${x}`]
                    }}</label>
                  </td>
                </tr>
              </table>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>


<script>
import ImagesGallery from "@/components/main/ImagesGallery.vue";
import StaticDataService from "@/services/StaticDataService.js";
import InspectionService from "@/services/InspectionService";
import BridgeService from "@/services/InspectionService";
import { mapState } from "vuex";

export default {
  name: "BridgeInspectionDetail",
  props: ["currentInspection"],
  components: {
    ImagesGallery,
  },
  data() {
    return {
      editInfo: false,
      datePickerMenu: false,
      imageDialog: false,
      componentTab: 0,
      inspection: {},
      selectedSpanNumber: 1,
      inspectionSpans: [],
      damageTypeId: null,
      dataStatusList: [],
      damages: [],
      images: [],
      inspectionWithHi: {},
      editedInspection: {
        inspection_type: null,
        inspector_name: null,
        inspection_date: null,
        inspection_data_status: null,
        note: null,
      }
    };
  },
  computed: {
    ...mapState("sysCode", ["sysComponents", "sysElements"]),
    editPath() {
      return `/bridge-management/${this.$route.params.id}/inspection/edit/${this.currentInspection.id}`;
    },
    bridgeId() {
      return this.$route.params.id;
    },
    inspectionId() {
      return this.$route.params.inspectionId ?? this.currentInspection.id;
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    selectedSpanIndex() {
      if (!this.currentInspection.InspectionSpans) {
        return -1;
      }
      return this.currentInspection.InspectionSpans.findIndex(
        (x) => x.span_number === this.selectedSpanNumber
      );
    },
    imagesList() {
      if (!this.currentInspection.InspectionSpans) {
        return [];
      }
      return this.currentInspection.InspectionSpans.map((x) =>
        x.ElementDamages.map((x) => x.ElementDamagePhotos).flat()
      )
        .flat()
    },
    formatedInspectionSpans() {
      return this.currentInspection.InspectionSpans.map((span, idx) => {
        return this.sysComponents.map(sysComponent => ({
          component: sysComponent,
          elements: this.sysElements.filter(x => x.component === sysComponent.code).map(e => ({
            element: e,
            damages: this.damages.filter(d =>
              d["InspectionSpans.span_number"] === idx + 1 &&
              d["InspectionSpans.ElementDamages.component"] === sysComponent.code &&
              d["InspectionSpans.ElementDamages.element"] === e.code
            ).map(d => ({
              ...d,
              images: this.imagesList.filter(i => i.element_damage_id === d["InspectionSpans.ElementDamages.id"])
            })).sort((a, b) => a["InspectionSpans.ElementDamages.damage_type_syscode.code"]
              .localeCompare(b["InspectionSpans.ElementDamages.damage_type_syscode.code"]))
          }))
        }))
      })
    },
    exportHeaders() {
      return [
        { text: this.$t('span'), value: `InspectionSpans.span_number` },
        { text: this.$t('component'), value: `InspectionSpans.ElementDamages.SysComponent.${this.description}` },
        { text: this.$t('element'), value: `InspectionSpans.ElementDamages.SysElement.${this.description}` },
        { text: this.$t("damage-type"), value: `InspectionSpans.ElementDamages.damage_type_syscode.${this.description}` },
        { text: 'A', value: `InspectionSpans.ElementDamages.damage_grade_a` },
        { text: 'B', value: `InspectionSpans.ElementDamages.damage_grade_b` },
        { text: 'C', value: `InspectionSpans.ElementDamages.damage_grade_c` },
        { text: 'D', value: `InspectionSpans.ElementDamages.damage_grade_d` },
        { text: 'E', value: `InspectionSpans.ElementDamages.damage_grade_e` },
      ]
    },
  },
  watch: {
    currentInspection: {
      handler(currentInspection) {
        this.resetEditedInspection();
      },
      immediate: true,
    },
    inspectionId: {
      handler() {
        if (this.inspectionId) {
          this.fetchDamages()
        }
      },
      immediate: true,
    }
  },
  async mounted() {
    this.dataStatusList = (await StaticDataService.index('inventory_data_status')).data;
  },
  methods: {
    async fetchDamages() {
      this.damages = (await InspectionService.getInspectionDamages(this.inspectionId)).data;
      const HIs = (await InspectionService.getInspectionWithHI(this.bridgeId)).data;
      this.inspectionWithHi = HIs.find(x => x.id === this.inspectionId)
    },
    startEditInspection() {
      this.editInfo = true;
      this.resetEditedInspection();

    },
    async saveEditInspection() {
      await InspectionService.upsertInspection(this.editedInspection);
      this.$emit('update');
      this.editInfo = false;
    },
    cancelEditInspection() {
      this.resetEditedInspection();
      this.editInfo = false;
    },
    resetEditedInspection() {
      this.editedInspection = {
        id: this.currentInspection.id,
        bridge_id: this.$route.params.id,
        inspection_year: this.currentInspection.inspection_year,
        inspection_type: this.currentInspection.inspection_type,
        inspector_name: this.currentInspection.inspector_name,
        inspection_date: this.currentInspection.inspection_date,
        note: this.currentInspection.note,
        inspection_data_status: this.currentInspection.inspection_data_status,
      };
    },
    showImages(damage) {
      this.damageTypeId = damage["InspectionSpans.ElementDamages.id"];
      this.imageDialog = true;
      this.images = damage.images
    },
    getImgUrl: (img) => {
      return `https://bms.mpwt.link/media/inspection/${img.url}`;
    },
  },
};
</script>

<style scoped>
table {
  background-color: #94c6ee;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid rgb(227, 233, 233);
  max-width: 1200px;
  padding: 2px;
  margin-left: 2px;
}

td,
th {
  width: 100px;
  border: 1px solid rgb(188, 194, 194);
  background-color: rgba(227, 233, 233);
  text-align: center;
  padding: 2px;
}

.full-width {
  width: 100%;
}
</style>